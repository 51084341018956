:root {
  --accent1: #b76aef;
  --accent2: #5c5c5c;
  --accent3: #f3ba38;
  --accent4: #5781ef;
}

.explo {
  background-color: var(--accent1);
  color: var(--accent1);
}

.explo.pill {
  border: 2px solid var(--accent1);
}

.collab {
  background-color: var(--accent3);
  color: var(--accent3);
}

.collab.pill {
  border: 2px solid var(--accent3);
}

.prob {
  background-color: var(--accent4);
  color: var(--accent4);
}

.prob.pill {
  border: 2px solid var(--accent4);
}

.work {
  width: 22px;
  height: 22px;
  overflow: hidden;
  rotate: 45deg;
}

.work .timelineIcon {
  width: 141%;
  height: 141%;
  transform: rotate(-45deg)translate(0, -20%);
  box-shadow: 0 4px 4px #00000040;
}

.adventure {
  width: 24px;
  height: 24px;
}

.project {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
}

body {
  color: #25313f;
  height: 100vh;
  background: radial-gradient(100% 138.89% at 100% 51.9%, #e0eded 0%, #fdfdfd 100%);
  margin: 0;
  font-family: Raleway, sans-serif;
}

h1 {
  font-family: Raleway;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
}

h2 {
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
}

h3 {
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

h4 {
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

h5 {
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

p {
  font-family: Titillium Web, Raleway, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

a {
  color: #25313f;
  text-decoration: none;
}

a:hover {
  color: #25313f;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  opacity: .2;
  background: #008b84;
  border-radius: 2px;
}

#frame {
  max-width: 1440px;
  height: calc(100vh - 40px);
  flex-direction: row;
  gap: 5px;
  margin: auto;
  padding-top: 20px;
  display: flex;
  position: relative;
}

#nav {
  height: calc(100vh - 40px);
  flex: 0 200px;
  margin-left: 10px;
}

#nav #headshotArea {
  width: 162px;
  height: 162px;
  background-color: #f2f6f6;
  border-radius: 50%;
  margin: auto;
  padding: 2px;
  box-shadow: 0 4px 4px #00000040;
}

#nav #headshotArea #headshotImage {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin: 1px auto auto;
  display: block;
}

#nav #name {
  text-align: center;
}

#nav #navLinkArea {
  position: absolute;
  bottom: 0;
}

#nav #navLinkArea h3 {
  padding-top: 15px;
}

#nav #navLinkArea .navLink {
  color: #25313f;
  -o-transition: color .1s;
  transition: color .1s ease-out;
}

#nav #navLinkArea .navLink:hover {
  color: #008b84;
  cursor: pointer;
}

#nav #navLinkArea a.active {
  opacity: .4;
}

#storiesMenu {
  height: 100%;
  flex-direction: row;
  flex: auto;
  gap: 20px;
  display: flex;
}

#vdivider {
  height: calc(100vh - 44px);
  border: 2px solid #008b84;
  border-radius: 2px;
  flex: 0 0 0;
  margin-right: 10px;
  position: relative;
}

#cardNav {
  height: calc(100vh - 40px);
  flex-flow: column;
  flex: 0 0 400px;
  display: flex;
}

#filterArea {
  flex: 0 0 200px;
}

#searchbarArea {
  width: 400px;
  height: 40px;
  vertical-align: middle;
  background-color: #f2f6f6;
  border: 1px solid #008b84;
  border-radius: 10px;
  display: table-cell;
}

#searchbarArea #searchbar {
  width: 90%;
  height: 30px;
  background: none;
  border: none;
  outline: none;
  margin-left: 10px;
  font-family: Raleway;
}

.filterSpan {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.filterLabel {
  writing-mode: tb-rl;
  color: #008b84;
  margin-left: 20px;
  margin-right: 15px;
  font-weight: 600;
  transform: rotate(-180deg);
}

.filterGap {
  height: 15px;
}

.filter {
  cursor: pointer;
  width: 110px;
  background-color: #f2f6f6;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
  box-shadow: 0 4px 4px #00000040;
}

.filterIconWrapper {
  vertical-align: middle;
  height: 100%;
  flex: auto;
  justify-content: center;
  margin: 10px 0;
  display: flex;
}

.filterIconWrapper .pill {
  width: 24px;
  height: 16px;
  border-radius: 8px;
}

.filterIconWrapper .shape {
  box-sizing: border-box;
  background-color: #008b84;
  border: 2px solid #008b84;
}

.filterIconWrapper .filterIcon {
  margin-right: 5px;
}

.filterIconWrapper .filterIcon.hidden {
  background-color: #0000;
}

.filterText {
  vertical-align: middle;
  text-align: center;
}

.filterText.hidden {
  opacity: .7;
}

.filterText h4 {
  white-space: pre-line;
  align-items: center;
  margin: 5px 0;
}

#hdivider {
  height: 0;
  border: 2px solid #008b84;
  border-radius: 2px;
  flex: 0 auto;
  margin-top: 5px;
}

#timelineFrame {
  flex: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  overflow-y: scroll;
}

#timelineIcons {
  flex-direction: column;
  gap: 60px;
  display: flex;
}

#timelineIcons h4 {
  display: inline;
  position: relative;
  top: -5px;
}

#timelineIcons .work {
  left: 100px;
}

#timelineIcons .adventure {
  left: 216px;
}

#timelineIcons .project {
  left: 332px;
}

.timelineIconHolder {
  display: inline-block;
  position: sticky;
  box-shadow: 0 4px 4px #00000040;
}

.timelineIcon {
  width: 100%;
  height: 100%;
}

#timelineHighlight {
  opacity: .1;
  width: 340px;
  height: 50px;
  background-color: #008b84;
  position: absolute;
  top: 0;
  right: 0;
}

#cardArea {
  height: calc(100vh - 65px);
  background: #f2f6f6;
  border-radius: 5px;
  flex: auto;
  padding: 10px 10px 15px 15px;
  overflow-y: auto;
  box-shadow: inset 0 0 10px #00000040;
}

#cardAreaContents {
  min-height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 15px;
  display: flex;
}

.card {
  width: 100%;
  height: 180px;
  -o-transition: color .1s;
  cursor: pointer;
  background: #fdfdfd;
  border-radius: 5px;
  flex-direction: row;
  flex: 0 0 180px;
  transition: color .1s ease-out;
  display: flex;
  overflow-y: hidden;
  box-shadow: 2px 2px 2px 1px #0000001a;
}

.card:hover {
  box-shadow: 2px 2px 3px 3px #0000001a;
}

.card:hover .cardTitle {
  color: #008b84;
}

.card .cardThemeBar {
  height: 100%;
  flex-direction: column;
  flex: 0 0 10px;
  display: flex;
}

.card .cardThemeBar .cardThemeBlock {
  flex: auto;
}

.card .thumbnailArea {
  width: 240px;
  height: 180px;
  margin-right: 24px;
}

.card .thumbnail {
  margin: auto;
  display: block;
}

.card .cardTextArea {
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 10px 0;
  display: flex;
}

.card .cardTitle {
  color: #25313f;
  -o-transition: color .1s;
  flex: none;
  margin: 0;
  transition: color .1s ease-out;
}

.card .cardDescript {
  flex: none;
  margin: 0;
}

#detail {
  z-index: 10;
  min-height: 100%;
  flex: auto;
  position: relative;
}

#detail.popout {
  background: #fdfdfd;
  box-shadow: 0 0 5px 5px #0000001a;
}

#storyCardHolder {
  max-width: 763px;
  margin: auto;
}

#loadingScreen {
  text-align: center;
  margin: auto;
}

#loadingScreen h1 div {
  float: right;
}

#summaryView {
  opacity: .9;
  width: 640px;
  height: 100%;
  background-color: #fdfdfd;
  position: relative;
  top: -100%;
  left: -230px;
}

#summaryView #summaryTextArea {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#summaryView #summaryText {
  text-align: center;
}

#summaryView #summaryButtonArea {
  width: 100%;
  justify-content: space-around;
  display: flex;
  position: absolute;
  bottom: 150px;
}

#summaryView #summaryButtonArea .iconOutline {
  flex: none;
}

.solidIcon {
  width: 50px;
  height: 50px;
  color: #008b84;
  cursor: pointer;
}

.solidIcon.muted, .solidIcon:hover {
  opacity: .7;
}

.iconOutline {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  outline: 3px solid #008b84;
}

.iconOutline .mute {
  opacity: .5;
}

.iconOutline .icon {
  height: 100%;
  margin: auto;
}

.iconOutline .icon ion-icon {
  color: #008b84;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.closeButton {
  box-sizing: border-box;
  z-index: 30;
  position: absolute;
  top: 20px;
  right: 20px;
}

.closeButton .solidIcon {
  width: 35px;
  height: 35px;
}

#slogan {
  text-align: center;
  margin: auto;
}

#slogan h1 {
  font-style: italic;
}

#testiArea {
  width: 100%;
}

.testiCard {
  width: 340px;
  background-color: #fdfdfd;
  border-radius: 20px;
  padding: 0 5px;
}

.testiCardQuote {
  font-weight: 600;
  position: relative;
  top: 5px;
  left: 10px;
}

.testiCardAuthor {
  color: #008b84;
  text-align: right;
  font-weight: 300;
  position: relative;
  bottom: 5px;
  right: 10px;
}

#landingPage {
  z-index: 20;
  width: 100vw;
  height: 100vh;
  background-color: #fdfdfd;
  justify-content: center;
  align-items: center;
  padding-bottom: 200px;
  padding-right: 200px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#landingArea {
  height: 700px;
  flex-direction: row;
  gap: 30px;
  display: flex;
  position: absolute;
}

#introImageArea {
  min-width: 200px;
  flex: 0 650px;
  justify-content: center;
  display: flex;
}

#introImage {
  width: 100%;
  object-fit: contain;
}

#landingInterface {
  flex: 0 0 500px;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

#landingText {
  padding-top: 150px;
}

#landingText h1 {
  font-size: 58px;
  font-weight: 600;
  display: inline;
}

#landingText #themeList {
  margin-top: 40px;
}

#landingText span {
  background: none;
  font-size: 21px;
}

#landingOptions {
  padding-top: 60px;
  padding-left: 60px;
}

#landingOptions #landingIntroOption {
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: row;
  margin-bottom: 10px;
  display: flex;
}

#landingOptions #landingIntroOption svg {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#landingOptions h3 {
  color: #008b84;
  font-size: 40px;
}

#landingOptions #landingMainOption {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
}

#landingOptions a {
  width: -moz-fit-content;
  width: fit-content;
  display: block;
}

#landingOptions a:hover {
  opacity: .5;
}

.Typist .Cursor {
  opacity: .3;
  display: inline-block;
}

.Typist .Cursor--blinking {
  animation: 1s step-start infinite blink;
}

@keyframes blink {
  0% {
    opacity: .3;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: .3;
  }
}

/*# sourceMappingURL=index.cb6c4e1e.css.map */
